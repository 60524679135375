import './ReportPage.css';
import '../ListTable/ListTable.css';

import React, { SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import moment from 'moment';
import { genUrl, getQP } from '../../utils/utils';
import MainStore from '../../store/mainStore';
import SidePanel from '../SidePanel';
import DateTimePicker from '../DateTimePicker';
import VehicleSelect from '../VehicleSelect/VehicleSelect';
import LoadSpinner from '../UI/LoadSpinner';
import { ReportType } from '../../store/reportStore';
import ReportTripDaily from '../ReportTripDaily'
import ReportSharedTrips from '../ReportSharedTrips';
import ReportVehicleActivity from '../ReportVehicleActivity';

interface ThisRouteProps {
    id: string;
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteProps>, WithTranslation {
    stores: MainStore;
}

@inject('stores')
@observer
class ReportPage extends React.Component<ThisComponentProps, {}> {
    props: any;

    @observable private hasMounted: boolean = false;

    @observable private _searchVehicles: string[] = [];
    @observable private _startTime: Date;
    @observable private _endTime: Date | undefined;
    @observable private _reportType: string = ReportType.VEHICLE_ACTIVITY;
    @observable private _loadedReportType: string | undefined = undefined; // indicates the loaded report for chart showing purpose

    @observable private _contract?: string | null = undefined;

    private exportName: string = '';

    constructor(props) {
        super(props);

        const [initialVehicles, initialStartTime, initialEndTime, initialContract] = [
            getQP(props.history.location.search, 'vehicles'),
            getQP(props.history.location.search, 'startTime'),
            getQP(props.history.location.search, 'endTime'),
            getQP(props.history.location.search, 'contract'),
        ];

        this._contract = initialContract;

        this._searchVehicles = initialVehicles ? initialVehicles.split(',') : [];
        this._startTime = initialStartTime
            ? new Date(initialStartTime)
            : moment().subtract(1, 'day').startOf('day').toDate();
        this._endTime = initialEndTime
            ? new Date(initialEndTime)
            : moment().subtract(1, 'day').endOf('day').toDate();

        // observe loaded contracts and set first item as default value if no previous
        reaction(
            () => props.stores.vehicleStore.contracts,
            (value, reaction) => {
                if (this._contract === undefined && value.length > 0) {
                    this._contract = value[0];
                }
            }
        );
    }

    componentDidMount(): void {
        this.hasMounted = true;
        this.submitSearchValues();
    }

    componentWillUnmount(): void {
        this.props.stores.uiStore.reportUrl = genUrl(
            this.props.location.pathname,
            this.props.location.search
        );
    }

    setSearchVehicles(e: string[]) {
        this._searchVehicles = e;
    }

    setSearchStartTime(e) {
        this._startTime = e[0];
        this._endTime = e.length <= 1 ? undefined : new Date(moment(e[1]).endOf('day').toDate());
    }

    setReportType(e) {
        this._reportType = e.target.value;
    }

    setContractSelection(e) {
        this._contract = e.target.value;
    }

    submitSearchValues(e: Undef<SyntheticEvent> = undefined) {
        const { t } = this.props;
        if (e) {
            e.preventDefault();
        }

        this.props.stores.reportStore.setSearchValues(
            this._searchVehicles,
            this._startTime,
            this._endTime,
            this._reportType,
            this._contract
        );

        // update URL
        const path: string = this.props.match.path;
        const [vehicles, startTime, endTime, contract] = [
            this._searchVehicles.join(','),
            this._startTime.toISOString(),
            this._endTime ? this._endTime.toISOString() : '',
            this._contract,
        ];

        const fileNameComponents = [
            t(`report.type.${this._reportType}`).replaceAll(' ', '-'),
            [
                moment(this._startTime).format('L'),
                this._endTime ? moment(this._endTime).format('L') : '',
            ].join('-'),
            this._searchVehicles.join(','),
            this._reportType === (ReportType.TRIP_DAILY_REPORT || ReportType.SHARED_TRIPS)
                ? this._contract
                : '',
        ].filter((i) => i && i.length && i !== '*');

        this.exportName = fileNameComponents.join('_');
        this._loadedReportType = this._reportType;

        this.props.history.push(
            genUrl(path, this.props.location.search, {
                vehicles,
                startTime,
                endTime,
                contract: contract === null ? 'null' : contract,
            })
        );
    }

    initFileDownload(uri, name) {
        const link = document.createElement('a');
        link.download = name;
        link.href = uri;
        link.click();
    }

    // @note currently this does not support two level heading hierarchies
    toCSV(fileName, tableId, separator: string | undefined = '\t') {
        const CSV_URI = 'data:text/csv;charset=utf-8,';
        const prepareCell = (cell) =>
            cell.innerText
                // replace all possible whitespaces and linebreaks with a regular space
                .replace(/\s+/gm, ' ')
                // remove leading and trailing spaces
                .trim()
                // escape all double-quotes by replacing them with doubled double-quotes
                .replace(/"/g, '""')
                // wrap the string with double-quotes
                .replace(/^(.*)$/, '"$1"');

        const prepareRow = (row) =>
            [...row.querySelectorAll('td, th')]
                // convert cell to a sanitized escaped string
                .map(prepareCell)
                // join these strings with a separator (comma by default)
                .join(separator);

        const ths = document.querySelectorAll(`table#${tableId} tr`);

        const data = Array.from(ths.values()) //[...(ths.values())]
            // convert row to a string of escaped comma separated values
            .map(prepareRow)
            // join these strings with a linebreak
            .join('\n');

        this.initFileDownload(`${CSV_URI}${encodeURIComponent(data)}`, `${fileName}.csv`);
    }

    render() {
        const { t, stores } = this.props;

        const allOption = { name: t('input.placeholder.selectAll'), number: '*' };

        const vehicles = stores.vehicleStore.vehicles;
        const contracts = stores.vehicleStore.contracts;
        const selectedVehicles = this._searchVehicles.map(
            (i) =>
                stores.vehicleStore.vehicles.find((v) => v.number === Number(i)) ||
                (i === '*' ? allOption : { number: i, name: '' })
        );

        const report = this.hasMounted && stores.reportStore.report;
        const isLoading = this.hasMounted && (!report || !report.data);

        const reportTypeOptions = Object.values(ReportType).map((i) => (
            <option key={i} value={i}>
                {t(`report.type.${i}`)}
            </option>
        ));
        const contractOptions = contracts.map((i) => (
            <option
                key={i + ''}
                value={i + ''}
                selected={this._contract !== undefined && this._contract === i}
            >{`${i === null ? t('report.noContract') : i}`}</option>
        ));
        const tripDailyReportSelected =
            this._reportType === ReportType.TRIP_DAILY_REPORT ||
            this._reportType === ReportType.SHARED_TRIPS;

        return (
            <div className="vehicle-page">
                <div className="vinka-containers">
                    <div className="vinka-containers-column">
                        <SidePanel className="side-panel-full side-panel-visible vinka-column-head">
                            <form onSubmit={this.submitSearchValues.bind(this)}>
                                <div className="form-row">
                                    <div className="col-8">
                                        {!tripDailyReportSelected && (
                                            <>
                                                <VehicleSelect
                                                    allOption={allOption}
                                                    options={vehicles}
                                                    value={selectedVehicles}
                                                    onChange={this.setSearchVehicles.bind(this)}
                                                />
                                            </>
                                        )}
                                        {tripDailyReportSelected && (
                                            <>
                                                <select
                                                    className="form-control form-control-sm"
                                                    value={this._contract + ''}
                                                    onChange={this.setContractSelection.bind(this)}
                                                >
                                                    {contractOptions}
                                                </select>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-2">
                                        <DateTimePicker
                                            value={[this._startTime, this._endTime]}
                                            enableTime={false}
                                            maxDate={'today'}
                                            pickerType="range"
                                            maxRange={32}
                                            onChange={this.setSearchStartTime.bind(this)}
                                        />
                                    </div>

                                    <div className="col-1">
                                        <select
                                            className="form-control form-control-sm"
                                            value={this._reportType}
                                            onChange={this.setReportType.bind(this)}
                                        >
                                            {reportTypeOptions}
                                        </select>
                                    </div>

                                    <div className="col-1">
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-secondary btn-block"
                                        >
                                            {t('button.search')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </SidePanel>

                        <SidePanel className="side-panel-full">
                            {isLoading && <LoadSpinner />}

                            {/* vehicle activity report */}
                            {!isLoading &&
                                this._loadedReportType === ReportType.VEHICLE_ACTIVITY &&
                                (<ReportVehicleActivity report={report} exportname={this.exportName} />)}

                            {/* trip daily report */}
                            {!isLoading &&
                                this._loadedReportType === ReportType.TRIP_DAILY_REPORT &&
                                (<ReportTripDaily report={report} toCSV={this.toCSV.bind(
                                    this,
                                    this.exportName)} />)}


                            {/* shared trips report */}
                            {!isLoading &&
                                this._loadedReportType === ReportType.SHARED_TRIPS &&
                                (<ReportSharedTrips report={report} toCSV={this.toCSV.bind(
                                    this,
                                    this.exportName)} />)}
                        </SidePanel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ReportPage);
