import './ReportTripDaily.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import SortTable from '../SortTable';

type ThisComponentProps = {
    report: any;
    toCSV: (elementId: string, colSeparator: string) => void;
};

const getColumns = (t) => {
    const tripDailyColumns = [
        {
            Header: t(`report.tripDailycolumn.date`),
            accessor: 'date',
        },
        {
            Header: t(`report.tripDailycolumn.bookedTrips`),
            accessor: 'bookedTrips',
        },
        {
            Header: t(`report.tripDailycolumn.aloneTrips`),
            accessor: 'aloneTrips',
        },
        {
            Header: t(`report.tripDailycolumn.sharedTrips`),
            accessor: 'sharedTrips',
        },
        {
            Header: t(`report.tripDailycolumn.totalTrips`),
            accessor: 'totalTrips',
        },
        {
            Header: t(`report.tripDailycolumn.sharingPercent`),
            accessor: 'sharingPercent',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.sharingPercent ? original.sharingPercent.toFixed(1) : '0.0'}
                    </div>
                );
            },
        },
        {
            Header: t(`report.tripDailycolumn.sharingForbiddenTrips`),
            accessor: 'sharingForbiddenTrips',
        },
        {
            Header: t(`report.tripDailycolumn.sharingForbiddenPassengers`),
            accessor: 'sharingForbiddenPassengers',
        },
        {
            Header: t(`report.tripDailycolumn.travelChainTrips`),
            accessor: 'travelChainTrips',
        },
        {
            Header: t(`report.tripDailycolumn.tripsPerRoute`),
            accessor: 'tripsPerRoute',
            Cell: ({ row: { original } }) => {
                return (
                    <div>{original.tripsPerRoute ? original.tripsPerRoute.toFixed(1) : '0.0'}</div>
                );
            },
        },
        {
            Header: t(`report.tripDailycolumn.lackOfTransitTrips`),
            accessor: 'lackOfTransitTrips',
        },
        {
            Header: t(`report.tripDailycolumn.myTaxiTrips`),
            accessor: 'myTaxiTrips',
        },
        {
            Header: t(`report.tripDailycolumn.myTaxiCustomers`),
            accessor: 'myTaxiCustomers',
        },
        {
            Header: t(`report.tripDailycolumn.totalRoutes`),
            accessor: 'totalRoutes',
        },

        {
            Header: t(`report.tripDailycolumn.distanceCleanVehicle`),
            accessor: 'distanceCleanVehicle',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.distanceCleanVehicle
                            ? (original.distanceCleanVehicle / 1000).toFixed(0)
                            : ''}
                    </div>
                );
            },
        },
        {
            Header: t(`report.tripDailycolumn.totalDistance`),
            accessor: 'totalDistance',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.totalDistance ? (original.totalDistance / 1000).toFixed(0) : ''}
                    </div>
                );
            },
        },
        {
            Header: t(`report.tripDailycolumn.directDistance`),
            accessor: 'directDistance',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.directDistance ? (original.directDistance / 1000).toFixed(0) : ''}
                    </div>
                );
            },
        },
        {
            Header: t(`report.tripDailycolumn.savedMilage`),
            accessor: 'savedMilage',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.savedMilage ? (original.savedMilage / 1000).toFixed(0) : ''}
                    </div>
                );
            },
        },
        // saved sum, just add empty column to match kela report
        {
            Header: t(`report.tripDailycolumn.savedAmount`),
            accessor: 'savedAmount', // does not exist
            Cell: ({ row: { original } }) => {
                return <div></div>;
            },
        },

        {
            Header: t(`report.tripDailycolumn.regularTaxiTrips`),
            accessor: 'regularTaxiTrips',
        },
        {
            Header: t(`report.tripDailycolumn.cleanVehicleTrips`),
            accessor: 'cleanVehicleTrips',
        },
        {
            Header: t(`report.tripDailycolumn.largeParatransitTrips`),
            accessor: 'largeParatransitTrips',
        },
        {
            Header: t(`report.tripDailycolumn.smallParatransitTrips`),
            accessor: 'smallParatransitTrips',
        },
        {
            Header: t(`report.tripDailycolumn.stretcherTrips`),
            accessor: 'stretcherTrips',
        },
        {
            Header: t(`report.tripDailycolumn.busTrips`),
            accessor: 'busTrips',
        },
        {
            Header: t(`report.tripDailycolumn.escortTrips`),
            accessor: 'escortTrips',
        },
        {
            Header: t(`report.tripDailycolumn.escortOnlyTrips`),
            accessor: 'escortOnlyTrips',
        },
        {
            Header: t(`report.tripDailycolumn.cancelledByCustomer`),
            accessor: 'cancelledByCustomer',
        },
        {
            Header: t(`report.tripDailycolumn.cancelledByNoVehicle`),
            accessor: 'cancelledByNoVehicle',
        },
        // amount of customers paid refund, just add empty column to match kela report
        {
            Header: t(`report.tripDailycolumn.paidRefundCustomers`),
            accessor: 'paidRefundCustomers', // does not exist
            Cell: ({ row: { original } }) => {
                return <div></div>;
            },
        },
        // amount of paid refund, just add empty column to match kela report
        {
            Header: t(`report.tripDailycolumn.paidRefund`),
            accessor: 'paidRefund', // does not exist
            Cell: ({ row: { original } }) => {
                return <div></div>;
            },
        },
        {
            Header: t(`report.tripDailycolumn.cleanVehicles`),
            accessor: 'cleanVehicles',
        },
    ];
    return tripDailyColumns;
};

const ReportTripDaily = ({ report, toCSV }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <>
            {report && report.data.length <= 0 && t(`report.noResults`)}

            {report && report.data.length > 0 && (
                <>
                    <button
                        onClick={() => toCSV('report-table', '\t')}
                        className="btn btn-sm btn-secondary"
                    >
                        {t('button.export')}
                    </button>

                    <SortTable id="report-table" data={report.data} columns={getColumns(t)} />
                </>
            )}
        </>
    );
};

export default ReportTripDaily;
